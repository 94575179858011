import React, { useState, useEffect, useRef } from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../layout/Layout";
import {
  dataToGQLProductDetails,
  dataToPDPBrandDetails,
  dataToProductDynamicDetails,
} from "../utils/product";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import ProductImages from "../components/products/ProductImages";
import ProductInfo from "../components/products/ProductInfo";
import useGetProductDetails from "../hooks/products/useGetProductDetails";
import Price from "../components/products/Price";
import OptionForm from "../components/products/OptionForm";
import RealtedProducts from "../sections/product/RelatedProducts";
import VideoSection from "../sections/product/VideoSection";
import DescriptionSection from "../sections/product/DescriptionSection";
import ReviewSection from "../sections/product/ReviewSection";
import useGetProductOptions from "../hooks/products/useGetProductOptions";
import { isUserLoggedIn } from "../utils/auth";
// import ProductsList from "../sections/product/ProductsList";
// import useGetPopularProducts from "../hooks/products/useGetPopularProducts";
import { PriceSkuContext } from "../context/PriceSkuContext";
// import { CartContext } from "../context/CartContext";
// import useGetCartSummary from "../hooks/cart/useGetCartSummary";
// import useRevalidateUserSession from "../hooks/useRevalidateUserSession";
import { useSelector } from "react-redux";
import { productMicroData, productBreadcrumbMicroData } from "../utils/common";
import { useLocation } from "@reach/router";
import useGetPopularProducts from "../hooks/products/useGetPopularProducts";
import ProductsList from "../sections/product/ProductsList";
import useCustomerGroupRestriction from "../hooks/customer/useCustomerGroupRestriction";
import QuestionSection from "../sections/product/AskQuestionSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faFacebookF,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { intersection } from "../utils/functions";
import useManageRecentlyViewedProductIds from "../hooks/products/useManageRecentlyViewedProductIds";
import Components from "../components/dynamic";

const ProductDetailsPageTemplate = ({
  location,
  data: {
    allBigCommerceProducts: { nodes },
    bigCommerceBrands,
  },
}) => {
  const user = isUserLoggedIn();

  const { brandName, brandUrl } = dataToPDPBrandDetails(bigCommerceBrands);

  const {
    productId,
    name,
    productUrl,
    sku,
    upc,
    orderQuantityMinimum,
    orderQuantityMaximum,
    weight,
    description,
    warranty,
    reviewCount,
    reviewRatingSum,
    availability,
    productCategoryIds,
    images,
    videos,
    customFields,
  } = dataToGQLProductDetails(nodes);

  const { lineItemsLength } = useSelector((state) => state.cart);
  const { currentLoginUser } = useSelector((state) => state.customer);
  const manageRecentlyViewedProductIds = useManageRecentlyViewedProductIds();

  const [reviewImage, setReviewImage] = useState({});

  const reviewSectionRef = useRef(null);
  const [activateReview, setActivateReview] = useState(false);

  const { isCustomerRestricted } = useCustomerGroupRestriction();
  // const location = useLocation();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    if (
      currentLoginUser?.category_access?.length &&
      currentLoginUser?.categories?.length &&
      intersection(currentLoginUser?.category_access, productCategoryIds).length
    ) {
      navigate("/403");
    }

    if (currentLoginUser?.id) {
      manageRecentlyViewedProductIds(productId, currentLoginUser);
    } else {
      manageRecentlyViewedProductIds(productId, false);
    }
  }, [
    currentLoginUser?.category_access,
    productCategoryIds,
    currentLoginUser?.categories?.length,
  ]);

  const { productDetails } = useGetProductDetails(productId, null, user);

  const { product_variants, isLoading } = useGetProductOptions(productId, user);

  const {
    basePrice,
    retailPrice,
    salePrice,
    relatedProducts,
    bulkPricingRules,
    isVisible,
    components,
  } = dataToProductDynamicDetails(productDetails);

  // conditionally redirect to 403 page if product is not visible
  useEffect(() => {
    if (isVisible === false) {
      navigate("/403");
    }
  }, [isVisible]);

  const [priceSku, setPriceSku] = useState({
    sku: sku || "",
    base_price: basePrice,
    retail_price: retailPrice,
    sale_price: salePrice,
    variantId: null,
  });

  useEffect(() => {
    if (basePrice || retailPrice || salePrice) {
      setPriceSku((prevState) => ({
        ...prevState,
        base_price: basePrice,
        retail_price: retailPrice,
        sale_price: salePrice,
      }));
    }
  }, [basePrice, retailPrice, salePrice]);

  useEffect(() => {
    if (priceSku?.variantId !== null) {
      setPriceSku((prevState) => ({
        ...prevState,
        base_price: priceSku?.base_price,
        retail_price: priceSku?.retail_price,
        sale_price: priceSku?.sale_price,
      }));
    }
  }, [priceSku?.variantId]);

  const { popularProducts } = useGetPopularProducts();

  const contextValue = {
    setPriceSku,
    priceSku,
  };
  return (
    <div className="page-wrapper">
      <div className="product-detail-wrap">
        <div className="container">
          <Breadcrumbs location={location} url={productUrl} />
        </div>
        <section className="product-view-section">
          <div className="container">
            <div className="bc-product-single">
              <div className="row flex flex-wrap vertical-top product-top-section">
                <div className="col product-image-section">
                  <section className="bc-product-single__top">
                    <ProductImages
                      images={images}
                      setReviewImage={setReviewImage}
                    />
                  </section>
                  {videos?.length > 0 ? <VideoSection videos={videos} /> : null}
                </div>
                <div className="col product-detail-section">
                  <div className="bc-product-single__meta">
                    <h2 className="product-brand-name">
                      {brandUrl ? (
                        <Link to={brandUrl}>{brandName}</Link>
                      ) : (
                        brandName
                      )}
                    </h2>
                    <h1 className="bc-product__title">{name}</h1>
                    <ReviewSection
                      reviewsCount={reviewCount}
                      reviews_rating_sum={reviewRatingSum}
                      images={reviewImage}
                      bigcommerce_id={productId}
                      name={name}
                      reviewSectionRef={reviewSectionRef}
                      setActivateReview={setActivateReview}
                    />
                    {user && <QuestionSection name={name} />}

                    {!isCustomerRestricted ? (
                      <Price
                        sale_price={priceSku["sale_price"]}
                        price={priceSku["base_price"]}
                        retail_price={priceSku["retail_price"]}
                        currentUser={currentLoginUser}
                      />
                    ) : null}
                    <ProductInfo
                      sku={priceSku["sku"]}
                      upc={upc}
                      availability={availability}
                      order_quantity_minimum={orderQuantityMinimum}
                      order_quantity_maximum={orderQuantityMaximum}
                      weight={weight}
                      weightUnit={weight.unit}
                      bulk_pricing_rules={bulkPricingRules}
                      customFields={customFields}
                    />
                    {!isCustomerRestricted ? (
                      <PriceSkuContext.Provider value={contextValue}>
                        <OptionForm
                          min_purchase_qty={orderQuantityMinimum}
                          max_purchase_qty={orderQuantityMaximum}
                          data={product_variants}
                          bigcommerce_id={productId}
                          isLoading={isLoading}
                          lineItemsLength={lineItemsLength}
                          availability={availability}
                        />
                      </PriceSkuContext.Provider>
                    ) : null}
                  </div>
                  <div className="social-share-links">
                    <div>
                      <FacebookShareButton url={location.href}>
                        <FontAwesomeIcon icon={faFacebookF} />
                      </FacebookShareButton>
                    </div>
                    <div>
                      <PinterestShareButton
                        url={location.href}
                        media={"Product"}
                        description={description}
                      >
                        <FontAwesomeIcon icon={faPinterest} />
                      </PinterestShareButton>
                    </div>
                    <div>
                      <TwitterShareButton
                        url={location.href}
                        title={"Check out this amazing product"}
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </TwitterShareButton>
                    </div>
                    <div>
                      <EmailShareButton
                        url={location.href}
                        subject={"This is the product"}
                        body={"Check out this amazing product"}
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </EmailShareButton>
                    </div>
                    <div>
                      <button>
                        <FontAwesomeIcon icon={faPrint} onClick={handlePrint} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {components?.length > 0
                ? components.map((item, index) => {
                    return (
                      <Components
                        data={item}
                        type={"product"}
                        key={`${item.code}-${index}`}
                      />
                    );
                  })
                : null}
              <div ref={reviewSectionRef}>
                <DescriptionSection
                  description={description}
                  warranty={warranty}
                  productId={productId}
                  activateReview={activateReview}
                  setActivateReview={setActivateReview}
                />
              </div>
              <RealtedProducts relatedProducts={relatedProducts} />

              <ProductsList
                title={"Popular Products"}
                subTitle={"Popular products of the Month"}
                products={popularProducts}
                disableBg={true}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

function ProductDetailsPage({ location, data }) {
  return (
    <ProductDetailsPageTemplate location={location} data={data} />
  );
}

export const Head = ({ pageContext, data }) => {
  const pageTitle = pageContext?.pageTitle
    ? pageContext?.pageTitle
    : `${pageContext?.name} | Midwest Distribution`;

  const metaDescription = pageContext.metaDescription
    ? pageContext.metaDescription.substring(0, 160)
    : "";

  const thumbnailImg =
    data.allBigCommerceProducts?.nodes?.[0]["images"]?.[0]?.["url_thumbnail"];
  const location = useLocation();
  const host = location.host;

  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:image" content={thumbnailImg} />
      <script type="application/ld+json">{productMicroData(data, host)}</script>
      <script type="application/ld+json">
        {productBreadcrumbMicroData(data, host)}
      </script>
    </>
  );
};

ProductDetailsPage.Layout = Layout
export default ProductDetailsPage;

export const query = graphql`
  query ($productId: String!, $brandId: Int) {
    allBigCommerceProducts(filter: { id: { eq: $productId } }) {
      nodes {
        bigcommerce_id
        categories
        custom_url {
          is_customized
          url
        }
        name
        price
        sku
        custom_fields {
          id
          name
          value
        }
        upc
        order_quantity_minimum
        order_quantity_maximum
        weight
        condition
        availability
        description
        warranty
        reviews_count
        reviews_rating_sum
        videos {
          id
          video_id
        }
        images {
          id
          is_thumbnail
          url_standard
          url_thumbnail
          url_zoom
          sort_order
        }
      }
    }
    bigCommerceBrands(bigcommerce_id: { eq: $brandId }) {
      name
      custom_url {
        url
      }
    }
  }
`;
