import React from "react";
import "./modal.css";
import { useFormik } from "formik";
import { ask_question_validation_schema } from "../../../utils/askQuestion";
import TextField from "../TextField";
import TextArea from "../TextArea";
import Button from "../button/Button";
import { getFormError } from "../../../utils/form";
import useShowQuestionModal from "../../../hooks/products/useAskQuestionModal";
import useAskQuestion from "../../../hooks/askQuestion/useAskQuestion";


export default function AskQuestionModal({
  onClose,    
  name
}) {    
  const { closeQuestionModal } = useShowQuestionModal()
  const { askQuestion } = useAskQuestion()

  const formik = useFormik({    
    initialValues: {
        product_name: name,       
        message: '',
      },
    validationSchema: ask_question_validation_schema,
    onSubmit: (values) => {        
        askQuestion(values);
        onClose();
        closeQuestionModal();
        formik.resetForm();
    },
  });

  return (
    <div className="question-modal-wrapper">     
       <form id="question-form" onSubmit={formik.handleSubmit}>            
           <div hidden>
                <div className="form-field">
                <TextField
                    id="product_name"
                    label={"Product Name"}
                    labelFor={"Product Name"}
                    name={"product_name"}
                    error={getFormError(formik.errors, "product_name")}
                    type={"text"}
                    onChange={formik.handleChange}
                    touched={formik.touched["product_name"]}
                    value={formik.values.product_name}
                    readOnly
                    disabled
                />
                </div>

                {/* <div className="form-field">
                <TextField
                    id="name"
                    label={"Name"}
                    labelFor={"Name"}
                    name={"name"}
                    error={getFormError(formik.errors, "name")}
                    type={"text"}
                    onChange={formik.handleChange}
                    touched={formik.touched["name"]}
                    value={formik.values.name}
                    readOnly
                />
                </div>
                <div className="form-field">
                <TextField
                    id="customer_id"
                    label={"Customer ID"}
                    labelFor={"Customer ID"}
                    name={"customer_id"}
                    error={getFormError(formik.errors, "customer_id")}
                    type={"text"}
                    onChange={formik.handleChange}
                    touched={formik.touched["customer_id"]}
                    value={formik.values.customer_id}
                    readOnly
                />
                </div>
                <div className="form-field">
                <TextField
                    id="email"
                    label={"Email"}
                    labelFor={"Email"}
                    name={"email"}
                    error={getFormError(formik.errors, "email")}
                    type={"email"}
                    onChange={formik.handleChange}
                    touched={formik.touched["email"]}
                    value={formik.values.email}
                    readOnly
                />
                </div>
                <div className="form-field">
                <TextField
                    id="phone_no"
                    label={"Phone"}
                    labelFor={"Phone"}
                    name={"phone_no"}
                    error={getFormError(formik.errors, "phone_no")}
                    type={"number"}
                    onChange={formik.handleChange}
                    touched={formik.touched["phone_no"]}
                    value={formik.values.phone_no}
                    readOnly
                />
                </div> */}
            </div>
            <div className="form-field">
              <TextArea
                id="message"
                label={"Enter your Question *"}
                labelFor={"Enter your Question *"}
                name={"message"}
                error={getFormError(formik.errors, "message")}                
                onChange={formik.handleChange}
                touched={formik.touched["message"]}
                value={formik.values.message}
                required
              />
            </div>
            

            <Button type="submit" className="button">
              SUBMIT QUESTION
            </Button>
          </form>
    </div>
  );
}
